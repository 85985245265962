<template id="app">
  <Header />
  <router-view class="grow" />
  <Footer v-show="showFooter" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import { useToast } from '@/libraries/vue-toast';
import { useRoleActionAccess } from '@/composition/useRoleActionAccess';
import { useWSChannelSubscribe } from '@/composition/useWebsocket';
import { sessionChannel } from '@/services/websocket';
import { paths } from '@/constants/routes';
import { killStorageItem } from '@/utils';

import Header from '@/components/Header/index.vue';
import Footer from '@/components/Footer.vue';

const { t } = useI18n();
const { role } = useRoleActionAccess();

const toast = useToast();
const store = useStore();
const router = useRouter();
const route = useRoute();

const version = process.env.VUE_APP_FULL_VERSION;

const showFooter = computed<boolean>(() => route.path !== paths.alerts.closed);

const resetPaymentQuery = () => {
  const query = { ...route.query };
  delete query.payment;
  router.replace({ query });
};

if (window.location.href.includes('payment=success')) {
  killStorageItem('basket');

  toast.open({
    message: t('paymentSuccess'),
    type: 'success',
    position: 'bottom-right',
  });
  resetPaymentQuery();
}

if (role.value) {
  const wsSubscribe = useWSChannelSubscribe(sessionChannel.value);
  wsSubscribe?.on('publication', ({ data }) => {
    if (data?.event === 'closed') {
      store.dispatch('clearUserData');
    }
  });
}

console.log(version);
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

:root {
  --color-white-darker: hsl(200, 74%, 90%); //Laboratory page
  --color-white-darkest: hsla(204, 100%, 98%, 0.878); //Laboratory page
  --color-grey-laboratory: hsla(0, 0%, 100%, 0.796); //Laboratory page
  --color-background: hsl(200, 37%, 97%);
  --color-backgroundTable-price: hsl(190, 81%, 92%); // Price
  --color-grey-header: hsl(202, 43%, 81%); //HeaderProfile
  --color-grey-header-background: hsl(202, 44%, 95%); //Header
  --color-grey-darker-herader: hsl(242, 100%, 95%); // HeaderProfile
  --color-grey-labs-category: hsl(220, 9%, 38%); // labs-category
  --color-grey-labs-filter: hsl(197, 15%, 59%); //labs-filter
  --color-grey-shop-form: hsl(197, 41%, 97%); // ShopForm
  --color-grey-shop-icon: hsl(207, 75%, 94%); //Shop Product
  --color-grey-header-shadow: hsla(198, 45%, 21%, 0.2); //Header
  --color-black-table-border: hsla(0, 0%, 0%, 0.25); // Price

  --color-black: #000000;
  --color-transparent: #ffffff00;

  --color-acent-price: hsl(188, 71%, 69%); // Price page
  --color-acent-lightest: hsl(195, 82%, 80%);
  --color-acent-lighter: hsl(195, 86%, 75%);
  --color-acent: hsl(195, 79%, 65%);
  --color-acent-darker: hsl(195, 34%, 54%);
  --color-acent-darkest: hsl(195, 66%, 36%);
  --color-acent-shop-product: hsl(194, 50%, 54%); //ShopProduct
  --color-acent-bonce: hsl(191, 93%, 60%); //ShopProduct

  --color-blue-lightest-product: hsl(206, 100%, 99%); // Shop product
  --color-blue-lighter-laboratory: hsl(204, 100%, 92%); // laboratoryPage
  --color-blue: hsl(206, 84%, 46%); // laboratoryPage
  --color-blue-darker: hsl(237, 50%, 36%); // laboratoryPage
  --color-blue-darkest-laboratory: hsl(0, 0%, 1%); // laboratoryPage
  --color-blue-shop-form: hsla(209, 41%, 43%, 0.161); // ShopForm
  --color-blue-darkest-footer: hsl(201, 100%, 9%); // footer
  --color-blue-labs: hsl(223, 35%, 51%); // labs

  --color-blue-blueberry-light: hsl(226, 85%, 69%); // ModalMenuItem
  --color-blue-blueberry: hsl(226, 85%, 64%); // ModalMenuItem, RecentCard

  --color-purple-shop: hsl(259, 100%, 58%); // Shop page
  --color-purple-lighter-header: hsl(243, 37%, 55%); // header-profile

  --color-green-lightest: hsl(117, 56%, 79%);
  --color-green-lighter: hsl(117, 64%, 75%);
  --color-green: hsl(117, 51%, 63%);
  --color-green-darker: hsl(117, 22%, 51%);
  --color-green-darkest: hsl(117, 58%, 34%);
  --color-green-price: hsl(155, 83%, 65%);

  --color-red: hsl(0, 100%, 50%); //labs-category-page
  --color-red-lighter: hsl(349, 100%, 59%); //labs-category-page
  --color-red-lightest: hsl(349, 100%, 91%);
  --color-pink-lighter: hsl(328, 66%, 55%); //labs-category-page
  --color-pink: hsl(306, 100%, 43%);

  --color-pink-lighter-price: hsl(306, 71%, 69%);
  --color-pink-lightest-price: hsl(313, 100%, 97%);

  --color-yellow: hsl(39, 100%, 66%);
  --color-yellow-darker: hsl(39, 43%, 56%);
  --color-yellow-darkest: hsl(39, 72%, 38%);

  --color-modal-background: hsla(220, 100%, 7%, 0.71);
}

body {
  background: var(--color-background);
}

#app {
  position: absolute;
  top: $header-height;
  overflow: auto;
  width: 100%;
  height: calc(100vh - $header-height);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-down("sm") {
    top: $header-height-mobile;
    height: calc(100vh - $header-height-mobile);
  }
}

.grow {
  flex-grow: 1;
}

.column {
  display: flex;
  flex-direction: column;
}
</style>
